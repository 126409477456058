import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
  FormsModule,
  ReactiveFormsModule
} from '@angular/forms';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { switchMap } from 'rxjs/operators';
import { LocalStorageKeys as LocalStorageKeys } from '../../models/server/Constants';
import { OrganisationService } from '../../services/organisation.service';
import { UserService } from '../../services/user.service';
import { AuthenticationService } from '../authentication.service';
import { WarningPanelComponent } from '../../comps/warning-panel/warning-panel.component';
import { SimpleProgressComponent } from '../../comps/simpleprogress.component';
import { FlexModule } from '@angular/flex-layout/flex';
import { BGAnimationComponent } from '../../comps/bganimation/bganimation.component';
import { MaterialModule } from '../../material.module';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
  standalone: true,
  imports: [MaterialModule, BGAnimationComponent, FlexModule, FormsModule, ReactiveFormsModule, SimpleProgressComponent, RouterLink, WarningPanelComponent]
})
export class SignInComponent implements OnInit {

  form: UntypedFormGroup;
  error: string;
  busy: boolean;
  showConfirm = false;

  constructor(
    private fb: UntypedFormBuilder,
    private router: Router,
    route: ActivatedRoute,
    private authService: AuthenticationService,
    private orgService: OrganisationService,
    private userService: UserService,
    private ngxLogger: NGXLogger) {

    this.createForm();

    route.queryParamMap.subscribe(
      params => {
        if (params.get('user')) {
          this.form.controls.email.setValue(params.get('user'));
        }
      });
  }

  ngOnInit(): void {

  }

  confirm(): void {
    this.router.navigate(['/confirm'], { queryParams: { user: this.form.controls.email.value } });
  }

  async login() {

    this.error = '';
    this.busy = false;
    this.showConfirm = false;

    if (this.form.valid) {
      this.busy = true;

      try {
        await this.authService.login(
          this.form.controls.email.value,
          this.form.controls.password.value).then(result => {

            localStorage.removeItem(LocalStorageKeys.DesignManager_ReturnToLabel);

            if (result) {
              this.orgService.resetCache().pipe(
                switchMap(_ =>
                  this.userService.resetCache()
                ),
                switchMap(_ =>
                  // get the org to force creation of it before we get any further
                  this.orgService.getOrganisation()
                ),
                switchMap(_ =>
                  this.userService.getUser())
              ).subscribe(user => {
                localStorage.removeItem(LocalStorageKeys.DesignManager_ReturnToLabel);
              });
            } else {
              this.error = 'You are not registered or your credentials are invalid.  Try again or register first.  If you have registered then check your email for a confirmation link.';
            }
          }).catch(err => {

            this.ngxLogger.error(err);
            this.busy = false;

            this.error = 'There was an unknown error.  Please try again later.';

            if (err.name === 'UserNotFoundException' || err.name === 'NotAuthorizedException') {
              this.error = 'You are not registered or your credentials are invalid.  Try again or register first.';
            } else if (err.name === 'UserNotConfirmedException') {
              this.error = 'You have not confirmed your login. Check your email for the confirmation code.';
              this.showConfirm = true;
            }
          });

      } finally {
        this.busy = false;
      }
    }

  }

  private createForm(): void {
    this.form = this.fb.group({
      email: new UntypedFormControl('', [Validators.required, Validators.email]),
      password: new UntypedFormControl('', Validators.required)
    })
  }
}
