import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../authentication.service';
import { WarningPanelComponent } from '../../comps/warning-panel/warning-panel.component';
import { SimpleProgressComponent } from '../../comps/simpleprogress.component';
import { FlexModule } from '@angular/flex-layout/flex';
import { BGAnimationComponent } from '../../comps/bganimation/bganimation.component';
import { MaterialModule } from '../../material.module';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  standalone: true,
  imports: [MaterialModule, BGAnimationComponent, FlexModule, FormsModule, ReactiveFormsModule, SimpleProgressComponent, WarningPanelComponent]
})
export class ConfirmComponent implements OnInit {

  form: UntypedFormGroup;
  error: string;
  busy: boolean;
  message: string;
  showInputs = false;

  private email: string = '';
  private code: string = '';

  constructor(
    private fb: UntypedFormBuilder,
    private router: Router,
    route: ActivatedRoute,
    private authService: AuthenticationService) {

    this.createForm();

    route.queryParamMap.subscribe(
      params => {
        if (params.get('user')) {
          this.email = params.get('user');
          this.form.controls.email.setValue(this.email);
        }
        if (params.get('ccode')) {
          this.code = params.get('ccode');
          this.form.controls.code.setValue(this.code);
        }
      });
  }

  ngOnInit(): void {

    if (this.form.valid && this.email && this.code) {
      this.confirm();
    }
  }

  async resend() {
    this.busy = true;
    this.message = 'Sending...';
    this.error = null;
    try {
      await this.authService.resendConfirmation(this.form.controls.email.value).then(ok => {
        if (ok) {
          // this.router.navigateByUrl('/');
        }
      }).catch(err => {

        this.error = err.message;

      });;
    } finally {

      this.busy = false;
    }
  }

  async confirm() {
    if (this.form.valid) {
      this.busy = true;
      this.message = 'Confirming...';
      this.error = null;

      try {
        await this.authService.confirm(this.form.controls.email.value, this.form.controls.code.value).then(ok => {

          if (ok) {
            this.router.navigate(['/signin'], { queryParams: { user: this.form.controls.email.value } });
          }
        }).catch(err => {

          this.error = err.message;

          if (err.name == 'NotAuthorizedException') {
            this.error = 'Could not confirm the specified email.  Check the email address and code.'
          } else
            if (err.name === 'CodeMismatchException') {
              this.error = 'Invalid code. Please try again.';
            } else if(err.name = 'UserNotFoundException') {
              this.error = 'User name not found or confirmation code is incorrect. Please try again.';
            }

        });

      } finally {
        this.busy = false;
      }
    }
  }

  private createForm(): void {
    this.form = this.fb.group({
      email: new UntypedFormControl(this.email, Validators.required),
      code: new UntypedFormControl(this.code, Validators.required)
    })
  }

}
