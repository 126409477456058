import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { AuthenticationService } from '../authentication.service';
import { WarningPanelComponent } from '../../comps/warning-panel/warning-panel.component';
import { SimpleProgressComponent } from '../../comps/simpleprogress.component';
import { FlexModule } from '@angular/flex-layout/flex';
import { BGAnimationComponent } from '../../comps/bganimation/bganimation.component';
import { MaterialModule } from '../../material.module';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  standalone: true,
  imports: [MaterialModule, BGAnimationComponent, FlexModule, FormsModule, ReactiveFormsModule, RouterLink, SimpleProgressComponent, WarningPanelComponent]
})
export class SignUpComponent {

  form: UntypedFormGroup;
  error: string;
  busy: boolean;
  registered: boolean;
  invited = false;
  orgName: string;

  constructor(
    private fb: UntypedFormBuilder,
    private router: Router,
    route: ActivatedRoute,
    private ngxLogger: NGXLogger,
    private authService: AuthenticationService) {

    this.createForm();
    route.queryParamMap.subscribe(
      params => {
        if (params.get('invitecode')) {
          this.form.controls.inviteCode.setValue(params.get('invitecode'));
          this.form.controls.inviteCode.disable();

          // if we have a code AND email AND org name then assume they are accepting an invite
          if (params.get('email') && params.get('org')) {

            this.form.controls.email.setValue(params.get('email'));
            this.form.controls.email.disable();

            this.orgName = params.get('org');
            this.form.controls.orgname.setValue(this.orgName);
            this.form.controls.orgname.disable();
            this.form.controls.inviteCode.disable();

            this.invited = true;
          }
        }

      });
  }

  tryAgain(): void {
    this.registered = false;
  }

  async register() {

    this.error = '';
    this.busy = false;
    if (this.form.valid) {
      this.busy = true;

      try {
        await this.authService.register(
          // this.form.controls.name.value,
          this.form.controls.email.value,
          this.form.controls.password.value,
          // this.form.controls.phone.value
          this.form.controls.inviteCode.value,
          this.form.controls.orgname.value
        ).then(result => {

          if (result) {
            // show confirmation code entry
            this.router.navigate(['/confirm'], { queryParams: { user: this.form.controls.email.value } });
            //this.registered = true;
          }
        }).catch(err => {

          this.ngxLogger.error('Error during signup', err);

          this.error = 'There was an unknown error.  Please try again later.';

          if (err.name == 'UsernameExistsException') {
            this.error = 'You are already registered.  Try to login.';
          }

        });

      } finally {
        this.busy = false;
      }
    }
  }

  private createForm(): void {
    this.form = this.fb.group({
      email: new UntypedFormControl('', [Validators.required, Validators.email]),
      // name: new FormControl('', Validators.required),
      // phone: "",
      password: new UntypedFormControl('', Validators.required),
      confirmpassword: new UntypedFormControl('', Validators.required),
      inviteCode: new UntypedFormControl(''),
      orgname: new UntypedFormControl('')

    })
  }
}
